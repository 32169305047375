import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"
import "../styles/main.scss"

// import Helmet from "react-helmet"
// import { withPrefix } from "gatsby"

const Layout = ({ className, children }) => {
  return (
    <>

      <Header />
      <main className={className}>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout



// <Helmet>
//     <script src={withPrefix('js/head.js')} type="text/javascript" />
// </Helmet>    