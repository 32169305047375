import { Link } from "gatsby";
import React from "react";
import imgLlamame from '../images/dibus/llamame.svg'
import { MdPhone } from "react-icons/md";

const BloqueContacto = () => (
	<section className="bloque bloque-contacto">
		<div className="container">
			<article className="box">
				<div className="row">
					<div className="col-md-6">
						<h3 className="title">¿Tienes alguna duda?</h3>
						<p className="intro">Ponte en contacto con nosotros. Puedes llamarnos o enviarnos un mensaje por Whatsapp. No hay nada más importante que una buena comunicación. </p>
					
						<div className="contacto-phone">
							<span className="phone-icon">
								<MdPhone />
							</span>

							<span className="phone-number">
								660 909 548
							</span>
						</div>
						<div className="contacto-footer">
							<p className="intro-contacto">O si lo prefires, también puedes escribirnos :)</p>
							<Link to="/contacto" className="btn btn-accent">Ir a contacto</Link>
						</div>
					</div>
					<div className="col-md-6">
						<img src={imgLlamame} title="Coco teléfono" alt="" />
					</div>			
				</div>
			</article>
		</div>
	</section>
)

export default BloqueContacto