import { Link } from "gatsby";
import React, {useState} from 'react';
import logo from '../images/logo-cocowat.png';
import { FiMenu, FiX } from "react-icons/fi";


const Menu = () => (
		<ul>
			<li><Link activeClassName="active" className="menu-item" title="Inicio Cocowat" to="/">Inicio</Link></li>
			<li><Link activeClassName="active" className="menu-item" title="Nuestros planes" to="/planes">Planes</Link></li>
			<li className="hidden"><Link activeClassName="active" title="Nuestros proyectos" className="menu-item" to="/proyectos">Proyectos</Link></li>
			<li><Link activeClassName="active" className="menu-item" title="Quienes somos" to="/quienes-somos">Quienes somos</Link></li>
			<li><Link activeClassName="active" className="menu-item" title="Contacta con cocowat" to="/contacto">Contacto</Link></li>
		</ul>
	);

const Header = props => {
	const [isNavVisible, setIsNavVisible] = useState(false);

	const toggleNav = () => {
		setIsNavVisible(!isNavVisible);
	}

	const closeNav = () => {
		setIsNavVisible(false);
	}

	return (
		<>
			<header className="header">
				<div className="header-container container">
					<button type="button" title="Menu" onClick={toggleNav} className="btn btn-transparent btn-menu"><FiMenu /></button>
				
					<div className="header-main">
						<Link className="header-logo-link" to="/">
							<img className="header-logo" src={logo} alt="Logo cocowat" title="Logo cocowat" />
						</Link>
					</div>

					<div className="header-menu">
						<Menu />
					</div>

				</div>
			</header>

			{isNavVisible && (
				<>
					<div className="sidebar">
					    <button className="btn-close-sidebar btn-transparent" onClick={closeNav} type="button"><FiX /></button>
						<Menu />
					</div>
					<div className="sidebar-overlay" role="menu" tabIndex={0} onClick={() => {}} onKeyDown={closeNav} />
				</>
				)
			}
		</>

	);
};

export default Header
